<template>
  <div>
    <sticky-header :title="$route.meta.title" />
    <div class="p-3">
      <b-card class="mb-4" body-class="d-flex flex-column flex-md-row">
        <div class="mr-3 mb-md-0 mb-3 w-75">
          <b-card-title class="font-weight-bold">Create Cleaning Jobs</b-card-title>
          <b-card-subtitle class="text-muted">Create cleaning jobs for bookings which have recently finished (and have not been created yet).</b-card-subtitle>
        </div>
        <b-button class="align-self-center w-25" @click.passive="cleaningJobs" :variant="success === 'cleaning-jobs' ? 'success' : 'dark'">
          <b-spinner small v-if="loading === 'cleaning-jobs'" />
          <span v-if="success === 'cleaning-jobs'"><fa-icon class="mr-1" icon="check" /> Success</span>
          <span v-if="loading !== 'cleaning-jobs' && success !== 'cleaning-jobs'">Run Action <fa-icon class="ml-1" icon="arrow-right" /></span>
        </b-button>
      </b-card>
      <b-card class="mb-4" body-class="d-flex flex-column flex-md-row">
        <div class="mr-3 mb-md-0 mb-3 w-75">
          <b-card-title class="font-weight-bold">Create Blank Statements</b-card-title>
          <b-card-subtitle class="text-muted">Create statements for property owners based on the latest state of bookings, costs, and cleaning jobs.</b-card-subtitle>
        </div>
        <b-button class="align-self-center w-25" @click.passive="genBlankStatements" :variant="success === 'gen-blank-statements' ? 'success' : 'dark'">
          <b-spinner small v-if="loading === 'gen-blank-statements'" />
          <span v-if="success === 'gen-blank-statements'"><fa-icon class="mr-1" icon="check" /> Success</span>
          <span v-if="loading !== 'gen-blank-statements' && success !== 'gen-blank-statements'">Run Action <fa-icon class="ml-1" icon="arrow-right" /></span>
        </b-button>
      </b-card>
      <b-card class="mb-4" body-class="d-flex flex-column flex-md-row">
      <div class="mr-3 mb-md-0 mb-3 w-75">
        <b-card-title class="font-weight-bold">Create Statements</b-card-title>
        <b-card-subtitle class="text-muted">Create statements for property owners based on the latest state of bookings, costs, and cleaning jobs.</b-card-subtitle>
      </div>
      <b-button class="align-self-center w-25" @click.passive="genStatements" :variant="success === 'gen-statements' ? 'success' : 'dark'">
        <b-spinner small v-if="loading === 'gen-statements'" />
        <span v-if="success === 'gen-statements'"><fa-icon class="mr-1" icon="check" /> Success</span>
        <span v-if="loading !== 'gen-statements' && success !== 'gen-statements'">Run Action <fa-icon class="ml-1" icon="arrow-right" /></span>
      </b-button>
    </b-card>
      <b-card class="mb-4" body-class="d-flex flex-column flex-md-row">
        <div class="mr-3 mb-md-0 mb-3 w-75">
          <b-card-title class="font-weight-bold">Send Statement Emails</b-card-title>
          <b-card-subtitle class="text-muted">Use this to send any queued emails to property owners that have recently had their statements finalised.</b-card-subtitle>
        </div>
        <b-button class="align-self-center w-25" @click.passive="emailStatements" :variant="success === 'email-statements' ? 'success' : 'dark'">
          <b-spinner small v-if="loading === 'email-statements'" />
          <span v-if="success === 'email-statements'"><fa-icon class="mr-1" icon="check" /> Success</span>
          <span v-if="loading !== 'email-statements' && success !== 'email-statements'">Run Action <fa-icon class="ml-1" icon="arrow-right" /></span>
        </b-button>
      </b-card>
    </div>
  </div>
</template>

<script>
import StickyHeader from '../components/StickyHeader.vue'

export default {
  components: { StickyHeader },
  data () {
    return {
      loading: undefined,
      success: undefined,
    }
  },
  methods: {
    async cleaningJobs () {
      try {
        this.loading = 'cleaning-jobs'
        await window.apiFetch('/run/cleaning-jobs')
        this.loading = undefined
        this.success = 'cleaning-jobs'
        setTimeout(() => this.success = undefined, 2500)
      } catch (error) {
        this.loading = undefined
      }
    },
    async emailStatements() {
      try {
        this.loading = 'email-statements'
        await window.apiFetch('/run/email-statements')
        this.loading = undefined
        this.success = 'email-statements'
        setTimeout(() => this.success = undefined, 2500)
      } catch (error) {
        this.loading = undefined
      }
    },
    async genBlankStatements() {
      try {
        this.loading = 'gen-blank-statements'
        await window.apiFetch('/run/gen-blank-statements')
        this.loading = undefined
        this.success = 'gen-blank-statements'
        setTimeout(() => this.success = undefined, 2500)
      } catch (error) {
        this.loading = undefined
      }
    },
    async genStatements() {
      try {
        this.loading = 'gen-statements'
        await window.apiFetch('/run/gen-statements')
        this.loading = undefined
        this.success = 'gen-statements'
        setTimeout(() => this.success = undefined, 2500)
      } catch (error) {
        this.loading = undefined
      }
    }
  }
}
</script>
